import React from 'react'
import { AdicionalInformationsTab } from './AdicionalInformations'
import { ContactTab } from './Contact'
import { FreightTab } from './Freight'
import { OverviewTab } from './Overview'
import { PaymentMethodTab } from './PaymentMethod'
import { EmailTab } from './Email'

export type TypeTabs = {
  name: string
  label: string
  isEnable: boolean
  isDefault?: boolean
  Component?: any
}

export const tabsList: TypeTabs[] = [
  {
    name: 'overview',
    label: 'Dados',
    isEnable: true,
    isDefault: true,
    Component: <OverviewTab />
  },
  {
    name: 'constact',
    label: 'Contato',
    isEnable: true,
    Component: <ContactTab />
  },
  // Removed at customer request
  // {
  //   name: 'email',
  //   label: 'Emails',
  //   isEnable: true,
  //   Component: <EmailTab />
  // },
  {
    name: 'informations',
    label: 'Informações adicionais',
    isEnable: true,
    Component: <AdicionalInformationsTab />
  },
  {
    name: 'payment',
    label: 'Forma de pagamento',
    isEnable: true,
    Component: <PaymentMethodTab />
  },
  {
    name: 'freight',
    label: 'Frete',
    isEnable: true,
    Component: <FreightTab />
  }
]
