import React from 'react'
import { AdicionalInformationsTab } from './AdicionalInformations'
import { ContactTab } from './Contact'
import { FreightTab } from './Freight'
import { OverviewTab } from './Overview'
import { PaymentMethodTab } from './PaymentMethod'
import { BudgetTab } from './Budget'
import { OrderTab } from './Order'
import { CancelledTab } from './Cancelled'
import { FinancialPendenciesTab } from './FinancialPendencies'
import { FinancialTransactionsTab } from './FinancialTransactions'
import { ResumeTab } from './Resume'
import { EmailTab } from './Email'
import ScheduleTab from './Schedule'

export type TypeTabs = {
  name: string
  label: string
  isEnable: boolean
  isDefault?: boolean
  Component?: any
}

export const tabsList: TypeTabs[] = [
  {
    name: 'resume',
    label: 'Resumo',
    isEnable: true,
    isDefault: true,
    Component: <ResumeTab />
  },
  {
    name: 'budget',
    label: 'Orçamento',
    isEnable: true,
    isDefault: false,
    Component: <BudgetTab />
  },
  {
    name: 'order',
    label: 'Pedido',
    isEnable: true,
    isDefault: false,
    Component: <OrderTab />
  },
  {
    name: 'cancelled',
    label: 'Cancelado',
    isEnable: true,
    isDefault: false,
    Component: <CancelledTab />
  },
  {
    name: 'financial_transactions',
    label: 'Movimentações Financeiras',
    isEnable: true,
    isDefault: false,
    Component: <FinancialTransactionsTab />
  },
  {
    name: 'financial_pendencies',
    label: 'Pendências Financeiras',
    isEnable: true,
    isDefault: false,
    Component: <FinancialPendenciesTab />
  },
  {
    name: 'overview',
    label: 'Dados',
    isEnable: true,
    isDefault: false,
    Component: <OverviewTab />
  },
  {
    name: 'constact',
    label: 'Contato',
    isEnable: true,
    Component: <ContactTab />
  },
  // {
  //   name: 'email',
  //   label: 'Emails',
  //   isEnable: true,
  //   Component: <EmailTab />
  // },
  {
    name: 'informations',
    label: 'Informações adicionais',
    isEnable: true,
    Component: <AdicionalInformationsTab />
  },
  {
    name: 'payment',
    label: 'Forma de pagamento',
    isEnable: true,
    Component: <PaymentMethodTab />
  },
  {
    name: 'freight',
    label: 'Frete',
    isEnable: true,
    Component: <FreightTab />
  },
  {
    name: 'schedule',
    label: 'Agenda',
    isEnable: true,
    Component: <ScheduleTab />
  }
]
