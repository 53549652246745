import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import Container from '../../../../components/Container'
import api from '../../../../services/api'
import { useToast } from '../../../../hooks/toast'
import { useLoading } from '../../../../hooks/loading'
import { Alert } from '../../../../components/Alert'
import { nameActions, namePageTitle } from '../domain/info'
import { apiDelete, apiList } from '../domain/api'
import { breadcrumbView } from '../domain/breadcrumb'
import {
  toolsViewCreate,
  toolsViewDelete,
  toolsViewUpdate,
  toolsViewList
} from '../domain/tools'
import Tabs from '../../../../components/Tabs'
import Tab from '../../../../components/Tabs/Tab'
import DataTable from '../../../../components/DataTable'
import Modal from '../../../../components/Modal'
import { FormInvestments } from '../components/Form'
import { useUpdateDataTable } from '../../../../hooks/dataTable'
import { headersTransaction } from '../domain/headers'
import { BRL } from '../../../../utlis/mask'
import moment from 'moment'
import {
  DATE_MASK_LOCALE_WITHOUT_TIME,
  DATE_MASK_LOCALE_WITH_TIME
} from '../../../../common/constants'

interface InvestmentData {
  id: number
  company_id: number
  name?: string
  bank?: { nome: string }
  company?: { name: string }
  date: string
  value: string
  balance: string
  user_id: number
  user_name?: string
  created_at: string
  updated_at: string
  childInvestments?: InvestmentData[]
}

const operationTypes: { [key: string]: string } = {
  AP: 'ATUALIZAÇÃO',
  R: 'RESGATE',
  A: 'APLICAÇÃO'
}

const InvestmentsView: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const location = useLocation()
  const { updateDataTable } = useUpdateDataTable()
  const [investment, setInvestment] = useState<InvestmentData | null>(null)
  const { addToast } = useToast()
  const searchParametersAuditLog = useRef([{ investment_origin_id: id }])
  const [modalCreate, setModalCreate] = useState(false)
  const [updatedBalance, setUpdatedBalance] = useState('0')
  const { disableLoading, activeLoading } = useLoading()

  const loadCategory = useCallback(async () => {
    activeLoading()
    try {
      const response = await api.get<InvestmentData>(apiList(id))
      const { data } = response
      const findLastChild = data?.childInvestments?.sort(
        (currentChildInvestment, nextChildInvestment) =>
          nextChildInvestment.id - currentChildInvestment.id
      )
      let balance = data.balance
      if (
        findLastChild?.[0] &&
        (BRL(findLastChild?.[0]?.balance).intValue ||
          BRL(findLastChild?.[0]?.balance).intValue === 0)
      ) {
        balance = BRL(findLastChild?.[0]?.balance).format()
      }
      setInvestment({
        ...data,
        balance
      })
      setUpdatedBalance(balance)
      disableLoading()
    } catch (err) {
      const [, baseUrl, path] = location.pathname.split('/')
      disableLoading()
      addToast({
        type: 'error',
        title: 'Error ao carregar a investimento',
        description:
          'Houve um error ao carregar a investimento, tente novamente mais tarde!'
      })
      if (path.includes('view')) {
        history.push(`/${baseUrl}`)
      } else {
        history.push(`/${baseUrl}/${path}`)
      }
    }
  }, [activeLoading, addToast, disableLoading, history, id, location.pathname])

  useEffect(() => {
    loadCategory()
  }, [
    activeLoading,
    addToast,
    disableLoading,
    history,
    id,
    loadCategory,
    location.pathname
  ])

  const [alertRemoveParent, setAlertRemoveParent] = useState(false)

  const handleOnClickRemoveParent = () => {
    setAlertRemoveParent(true)
  }

  const handlerOnClickButtonConfirmRemoveParent = async (id: number) => {
    try {
      await api.delete(apiDelete(String(id)))
      setAlertRemoveParent(false)
      addToast({
        type: 'success',
        title: 'Atributo removido com sucesso.'
      })
      history.goBack()
    } catch (err: any) {
      setAlertRemoveParent(false)
      if (err.response.status === 403) {
        addToast({
          type: 'error',
          title: err.response.data.message
        })
        return
      }
      addToast({
        type: 'error',
        title: 'Atributo não removido, pois ainda está sendo usada.'
      })
    }
  }

  const handlerOnClickButtonCancelRemoveParent = () => {
    setAlertRemoveParent(false)
  }

  const handleClickOnOpenModalCreate = () => {
    setModalCreate(true)
  }

  const handleClickOnClose = async () => {
    setModalCreate(false)
    updateDataTable()
    await loadCategory()
  }

  return (
    <>
      <Container
        pageTitle={namePageTitle}
        portletTitle={nameActions.read.name}
        breadcrumb={breadcrumbView}
        tools={[
          toolsViewUpdate(String(id)),
          toolsViewDelete(() => {
            handleOnClickRemoveParent()
          }),
          toolsViewCreate(),
          toolsViewList()
        ]}
      >
        <div className="form-body">
          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="id" className="control-label">
                  Cód.
                </label>
                <p>{investment?.id}</p>
              </div>
            </div>
            <div className="col-md-10">
              <div className="form-group">
                <label htmlFor="id" className="control-label">
                  Nome
                </label>
                <p>{investment?.name}</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="name" className="control-label">
                  Empresa
                </label>
                <p>{investment?.company?.name}</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="type" className="control-label">
                  Conta Bancaria
                </label>
                <p>{investment?.bank?.nome}</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="type" className="control-label">
                  Data de Aplicação
                </label>
                <p>
                  {moment(investment?.date, DATE_MASK_LOCALE_WITH_TIME)
                    .add(3, 'h')
                    .format(DATE_MASK_LOCALE_WITHOUT_TIME)}
                </p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="created" className="control-label">
                  Valor Aplicado
                </label>
                <p>{investment?.value}</p>
              </div>
            </div>
            <div className="col-md-2">
              <div className="form-group">
                <label htmlFor="updated" className="control-label">
                  Saldo Atualizado
                </label>
                <p>{investment?.balance}</p>
              </div>
            </div>
          </div>
          <p>&nbsp;</p>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Tabs>
              <Tab title="Transações">
                <div className="portlet light">
                  <div className="portlet-title">
                    <div className="caption">Listagem</div>
                    <div className="tools">
                      {!!(BRL(updatedBalance).intValue > 0) && (
                        <div
                          onClick={handleClickOnOpenModalCreate}
                          style={{ cursor: 'pointer' }}
                        >
                          <i className="fa fa-plus" /> Adicionar
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="portlet-body form">
                    <DataTable
                      format={{ orderBy: 'id' }}
                      source="investmentTransaction"
                      entity="Investment"
                      headers={headersTransaction}
                      searchParameters={searchParametersAuditLog.current}
                      customHeaders={[
                        {
                          name: 'Operação',
                          field: 'operation',
                          sortable: true,
                          element: item => {
                            return <p>{operationTypes[item.operation]}</p>
                          }
                        },
                        {
                          name: 'IOF',
                          field: 'iof',
                          sortable: false,
                          element: item => {
                            return <p>{item.operation === 'R' && item.iof}</p>
                          }
                        },
                        {
                          name: 'Imp. de renda',
                          field: 'income_tax',
                          sortable: false,
                          element: item => {
                            return (
                              <span>
                                {item.operation === 'R' && item.income_tax}
                              </span>
                            )
                          }
                        }
                      ]}
                    />
                  </div>
                </div>
              </Tab>
              <></>
            </Tabs>
          </div>
        </div>
      </Container>
      {modalCreate && (
        <Modal
          onClickButtonCancel={handleClickOnClose}
          isOpenModal={modalCreate}
          pageTitle="Adicionar Transação"
          Children={
            <FormInvestments
              typeForm="transaction"
              initialValues={{
                ...investment,
                value: undefined,
                date: undefined
              }}
              isOpenInModal={{
                handleOnClose: handleClickOnClose
              }}
            />
          }
        />
      )}
      <Alert
        message={`Tem certeza que deseja excluir o registro ${investment?.id} ?`}
        onClickCancellButton={handlerOnClickButtonCancelRemoveParent}
        onClickConfirmButton={() =>
          handlerOnClickButtonConfirmRemoveParent(Number(investment?.id))
        }
        isActive={alertRemoveParent}
      />
    </>
  )
}

export default InvestmentsView
