import React from 'react'
import { Tab } from '../presentation/components/tabs/index'
import { ClientProvider } from '../presentation/providers/ClientProvider'
import Container from '../../../../components/Container'
import { breadcrumbCreate } from '../domain/breadcrumb/create'
import { namePageTitle, nameActions } from '../domain/info'
import { TabsProvider } from '../../../../hooks/tabs'
import { tabsList } from '../presentation/components/tabs/tabsList'
import { toolsCreate } from '../domain/tools'

const CreateClientPage = (): JSX.Element => {
  return (
    <TabsProvider>
      <ClientProvider>
        <Container
          pageTitle={namePageTitle}
          portletTitle={nameActions.create.name}
          breadcrumb={breadcrumbCreate}
          tools={[toolsCreate]}
        >
          <Tab tabList={tabsList} />
        </Container>
      </ClientProvider>
    </TabsProvider>
  )
}

export default CreateClientPage
