import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'

import api from '../../../../../services/api'
import { useToast } from '../../../../../hooks/toast'
import { useLoading } from '../../../../../hooks/loading'
import { useUpdateDataTable } from '../../../../../hooks/dataTable'
import { apiCreate, apiUpdate } from '../../domain/api'
import { nameActions } from '../../domain/info'

import Form, { Input, Select } from '../../../../../components/Form'
import { Date as DatePicker } from '../../../../../components/Form/date'
import ReactInputMask from 'react-input-mask'
import { genericMaskWithTwoZeroWithPoint } from '../../../../../utlis/mask'
import { Alert } from '../../../../../components/Alert'
import moment from 'moment'
import {
  DATE_MASK_LOCALE_WITHOUT_TIME,
  DATE_MASK_LOCALE_WITH_TIME
} from '../../../../../common/constants'

type InvestmentData = {
  id: number
  company_id?: number
  category_id?: string
  category_name?: string
  sub_category_name?: string
  bank_account_id?: string
  bank_account_name?: string
  investment_origin_id?: string
  financial_transaction_id?: string
  name?: string
  date?: string
  value?: string
  balance?: string
  operation?: string
  user_id?: number
  user_name?: string
  sub_category_id?: string
}

type Company = {
  id: number
  name: string
}

type Bank = {
  id: number
  nome: string
  empresa_id: number
}

type IsOpenInModalProps = {
  handleOnClose: () => void
}

type TypesFormProps = {
  initialValues?: InvestmentData & {
    idUpdate?: number
  }
  isOpenInModal?: IsOpenInModalProps
  typeForm: 'create' | 'update' | 'transaction'
}

export const FormInvestments = ({
  typeForm,
  initialValues,
  isOpenInModal
}: TypesFormProps): JSX.Element => {
  const { activeLoading, disableLoading } = useLoading()
  const { addToast } = useToast()
  const history = useHistory()
  const { updateDataTable } = useUpdateDataTable()
  const [defaultValues, setDefaultValues] = useState<InvestmentData>()
  const [companies, setCompanies] = useState<Company[]>([])
  const [banks, setBanks] = useState<Bank[]>([])
  const [dateValue, setDateValue] = useState<Date>()
  const [companyId, setCompanyId] = useState<number>()
  const [bankAccountId, setBankAccountId] = useState<string>('')
  const [operation, setOperation] = useState<string>(
    typeForm === 'transaction' ? 'AP' : 'A'
  )
  const [value, setValue] = useState<string>('0,00')
  const [iof, setIof] = useState<string>('0,00')
  const [incomeTax, setIncomeTax] = useState<string>('0,00')

  const buttonSubmitRef = useRef<HTMLButtonElement>()
  const [alert, setAlert] = useState(false)
  const loadCompanies = async () => {
    const { data } = await api.get('financial/configurations')
    setCompanies(data)
  }
  const loadBanks = async () => {
    const { data } = await api.get('financial/bankAccountsOld')
    setBanks(data)
  }

  useEffect(() => {
    loadCompanies()
    loadBanks()
  }, [])

  useEffect(() => {
    if (initialValues) {
      if (initialValues?.date) {
        setDateValue(
          moment(initialValues.date, DATE_MASK_LOCALE_WITH_TIME)
            .add(1, 'd')
            .toDate()
        )
      }
      delete initialValues.date
      setDefaultValues({
        ...initialValues
      })
      setCompanyId(initialValues.company_id)
      setBankAccountId(initialValues.bank_account_id)
      setValue(initialValues.value)
    }
  }, [initialValues])

  const onSubmit = async (data: InvestmentData) => {
    const findBank = banks.find(bank => bank.id === Number(bankAccountId))
    const requestDateValue = moment(
      dateValue.toLocaleDateString(),
      DATE_MASK_LOCALE_WITHOUT_TIME
    ).toISOString()
    if (!dateValue) {
      addToast({
        title: 'Campo obrigatório',
        description: 'Campo data é obrigatório',
        type: 'info'
      })
      return
    }
    if (typeForm === 'create') {
      try {
        const dataCreate = {
          ...data,
          date: requestDateValue,
          bank_account_name: findBank?.nome,
          operation: 'A',
          category_id: 8,
          category_name: 'INVESTIMENTO',
          sub_category_id: 67,
          sub_category_name: 'APLICACAO'
        }
        activeLoading()
        await api.post(apiCreate(), dataCreate)
        disableLoading()
        history.push(nameActions.read.to)
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao adicionar o registro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        disableLoading()
        updateDataTable()
      }
    }
    if (typeForm === 'transaction') {
      try {
        const dataCreate = {
          ...data,
          company_id: defaultValues?.company_id,
          category_id: operation === 'R' ? 157 : 8,
          category_name: 'INVESTIMENTO',
          sub_category_id: operation === 'R' ? 180 : 67,
          sub_category_name: operation === 'R' ? 'RESGATE' : 'APLICACAO',
          bank_account_id: defaultValues?.bank_account_id,
          bank_account_name: defaultValues?.bank_account_name,
          name: defaultValues?.name,
          date: requestDateValue,
          balance: defaultValues?.balance,
          operation: data?.operation,
          investment_origin_id: Number(defaultValues?.id)
        }
        activeLoading()
        await api.post(apiCreate(), dataCreate)
        disableLoading()
        isOpenInModal?.handleOnClose?.()
      } catch (error) {
        addToast({
          type: 'error',
          title: 'Erro ao adicionar o registro',
          description:
            'Ocorreu um erro ao fazer cadastro, por favor, tente novamente.'
        })
        disableLoading()
        updateDataTable()
      }
    }
    if (typeForm === 'update') {
      const dataUpdate = {
        ...data,
        date: requestDateValue,
        bank_account_name: findBank?.nome
      }
      const id = initialValues?.idUpdate

      try {
        activeLoading()
        await api.put(apiUpdate(String(id)), dataUpdate)
        updateDataTable()
        disableLoading()
        history.push(nameActions.read.to)
        addToast({
          type: 'success',
          title: 'Registro atualizado',
          description: 'Registro alterado com sucesso'
        })
      } catch (error) {
        history.push(nameActions.read.to)
        addToast({
          type: 'error',
          title: 'Erro ao atualizar o registro',
          description:
            'Ocorreu um erro ao fazer a atualização, por favor, tente novamente.'
        })
      }
    }
    disableLoading()
  }

  const handlerOnClickButtonCancel = () => {
    setAlert(false)
  }

  const handlerOnClickButtonConfirm = async () => {
    buttonSubmitRef?.current?.click()
    setAlert(false)
  }

  return (
    <Form onSubmit={onSubmit} defaultValues={defaultValues}>
      {typeForm === 'transaction' ? (
        <div className="row">
          <div className="col-md-6">
            <Select
              label="Operação"
              className="form-control"
              name="operation"
              options={[
                {
                  name: 'ATUALIZAÇÃO',
                  value: 'AP'
                },
                {
                  name: 'RESGATE',
                  value: 'R'
                }
              ]}
              value={operation}
              onChange={event => {
                setOperation(event.target.value)
              }}
              controlled
              blank
              rules={{ required: true }}
            />
          </div>
          <div className="col-md-6">
            <DatePicker
              name="date"
              className="form-control"
              label="Data (Data que aparece no extrato bancário)"
              placeholderText="DD/MM/AAAA"
              dateFormat="dd/MM/yyyy"
              selected={dateValue}
              maxDate={moment().toDate()}
              onChange={date => {
                setDateValue(date)
              }}
              controlled
              rules={{ required: true }}
            />
          </div>
          <div className="col-md-6">
            <Input
              label="Valor"
              name="value"
              value={value}
              onChange={event => {
                setValue(genericMaskWithTwoZeroWithPoint(event.target.value))
              }}
              className="form-control"
              rules={{ required: true }}
              controlled
            />
          </div>

          {operation === 'R' && (
            <>
              <div className="col-md-6">
                <Input
                  label="IOF"
                  name="iof"
                  value={iof}
                  onChange={event => {
                    setIof(genericMaskWithTwoZeroWithPoint(event.target.value))
                  }}
                  className="form-control"
                  controlled
                />
              </div>
              <div className="col-md-6">
                <Input
                  label="Imposto de renda"
                  name="income_tax"
                  value={incomeTax}
                  onChange={event => {
                    setIncomeTax(
                      genericMaskWithTwoZeroWithPoint(event.target.value)
                    )
                  }}
                  className="form-control"
                  controlled
                />
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          <div className="row">
            <div className="col-md-12">
              <Input
                label="Nome"
                name="name"
                className="form-control"
                rules={{ required: true }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <Select
                label="Empresa"
                className="form-control"
                name="company_id"
                options={companies.map(c => ({
                  name: c.name,
                  value: c.id
                }))}
                onChange={e => {
                  setCompanyId(Number(e.target.value))
                  setBankAccountId('')
                }}
                blank
                defaultValue={''}
                controlled
                rules={{ required: true }}
              />
            </div>
            <div className="col-md-3">
              <Select
                label="Conta Bancária"
                className="form-control"
                name="bank_account_id"
                options={banks
                  .filter(bank => bank.empresa_id === companyId)
                  .map(bank => ({
                    value: String(bank.id),
                    name: bank.nome
                  }))}
                onChange={e => setBankAccountId(e.target.value)}
                value={bankAccountId}
                blank
                defaultValue={''}
                fullControlled
                rules={{ required: true }}
              />
            </div>
            <div className="col-md-2">
              <Select
                label="Tipo de Investimento"
                className="form-control"
                name="sub_category_id"
                options={[
                  {
                    name: 'APLICACAO',
                    value: 67
                  }
                ]}
                blank
                defaultValue=""
                rules={{ required: true }}
              />
            </div>
            <div className="col-md-2">
              <Input
                label="Valor"
                name="value"
                value={value}
                onChange={event => {
                  setValue(genericMaskWithTwoZeroWithPoint(event.target.value))
                }}
                className="form-control"
                rules={{ required: true }}
                controlled
              />
            </div>
            <div className="col-md-3">
              <DatePicker
                name="date"
                className="form-control"
                label="Data (Data que aparece no extrato bancário)"
                placeholderText="DD/MM/AAAA"
                dateFormat="dd/MM/yyyy"
                selected={dateValue}
                customInput={
                  <ReactInputMask maskPlaceholder={null} mask="99/99/9999" />
                }
                onChange={date => {
                  setDateValue(date)
                }}
                controlled
              />
            </div>
          </div>
        </>
      )}
      <div className="form-actions right">
        <button
          type="submit"
          className="invisible position-absolute"
          ref={buttonSubmitRef}
        ></button>
        <button
          type="button"
          className="btn dark btn-sm bold uppercase"
          onClick={() => {
            if (operation !== 'AP' && typeForm === 'create') {
              setAlert(true)
              return
            }
            buttonSubmitRef?.current?.click()
          }}
        >
          Salvar
        </button>
      </div>
      <Alert
        message={'Confirma a realização da transação financeira ?'}
        onClickCancellButton={handlerOnClickButtonCancel}
        onClickConfirmButton={handlerOnClickButtonConfirm}
        isActive={alert}
      />
    </Form>
  )
}
