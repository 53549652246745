import React, { useCallback, useEffect, useRef, useState } from 'react'
import Container from '../../../../components/Container'
import DataTable from '../../../../components/DataTable'
import { nameEntity, namePageTitle, nameSource } from '../domain/info'
import { headers } from '../domain/headers'
import { breadcrumbList } from '../domain/breadcrumb'
import { toolsList } from '../domain/tools'
import { useHistory } from 'react-router-dom'
import Form, { Input, Select } from '../../../../components/Form'
import { Filter } from '../../../../components/Filter'
import { ListTitle } from '../../../../styles'
import api from '../../../../services/api'
import { cnpjMask, cpfMask } from '../../../../utlis/mask'
import { useAuth } from '../../../../hooks/auth'
import DropdownList from 'react-widgets/esm/DropdownList'
const ClientsList = (): JSX.Element => {
  const [cnpj, setCnpj] = useState('')

  const { segments } = useAuth()

  const [parameters, setParameters] = useState<any>()
  const fields = useRef<string[]>([
    `${nameSource}.id`,
    'person.name',
    'personAddress.state',
    'personAddress.city',
    'person.fantasy_name',
    'person.cnpj_cpf',
    'segment.name',
    'subSegment.name',
    'saleSeller.name',
    'locationSeller.name'
  ])

  const history = useHistory()

  const [defaultValues, setDefaultValues] = useState<any>()

  const [originsOptions, setOriginsOptions] = useState([])
  const [segmentsOptions, setSegmentsOptions] = useState([])
  const [allSegmentsOptions, setAllSegmentsOptions] = useState([])
  const [economicGroupsOptions, setEconomicGroupsOptions] = useState([])
  const [subSegmentsOptions, setSubSegmentsOptions] = useState([])
  const [subSegmentsData, setSubSegmentsData] = useState([])
  const [clientsTypesOptions, setClientsTypesOptions] = useState([])
  const [subSegmentsSelected, setSubSegmentsSelected] = useState('')

  const [segmentSelected, setSegmentSelected] = useState('')
  const getOptions = useCallback(async () => {
    const { data } = await api.get('commercial/clients/selectOptions')
    setOriginsOptions(
      data.origins?.map((type: any) => ({
        value: type.id,
        name: type.name
      }))
    )
    setAllSegmentsOptions(
      data.segments?.map((type: any) => ({
        value: type.id,
        parent_id: type.parent_id,
        name: type.name
      }))
    )
    setSegmentsOptions(
      data.segments
        ?.filter((segment: any) => {
          if (segments.length === 0) {
            return true
          }
          return segments?.includes(String(segment.id))
        })
        ?.filter((segment: any) => !segment.parent_id)
        ?.map((type: any) => ({
          value: type.id,
          parent_id: type.parent_id,
          name: type.name
        }))
    )
    setSubSegmentsData(
      data.segments
        ?.filter((segment: any) => !!segment.parent_id)
        ?.map((type: any) => ({
          value: type.id,
          parent_id: type.parent_id,
          name: type.name
        }))
    )
    setEconomicGroupsOptions(
      data.clientEconomicGroups?.map((type: any) => ({
        value: type.id,
        name: type.name
      }))
    )
    setClientsTypesOptions(
      data.clientsTypes?.map((type: any) => ({
        value: type.id,
        name: type.name
      }))
    )
  }, [segments])

  const onSearch = async (data: any) => {
    console.log(data)
    const query: string[] = []

    Object.keys(data).forEach(field => {
      if (data[field]) {
        query.push(`${field}=${data[field]}`)
      }
    })

    history.replace(`/commercial/clients?${query.join('&')}`)
    data.segments = segments
    setParameters([data])
  }

  useEffect(() => {
    getOptions()
    const data: any = {}
    const url = new URL(window.location.href)
    url.search
      .replace('?', '')
      .split('&')
      .forEach(entrie => {
        const [key, value] = entrie.split('=')
        if (value) {
          data[key] = value
        }
      })
    setDefaultValues(data)

    data.segments = segments
    setParameters([data])
  }, [getOptions, segments])

  return (
    <Container
      pageTitle={namePageTitle}
      portletTitle={'Filtro'}
      breadcrumb={breadcrumbList}
      tools={toolsList}
    >
      <Filter>
        <Form onSubmit={onSearch} defaultValues={defaultValues}>
          <div className="col-md-2">
            <Input
              label="CNPJ/CPF"
              className="form-control"
              name="person__cnpj_cpf"
              onChange={e => {
                if (e.target.value.length <= 14) {
                  setCnpj(cpfMask(e.target.value))
                } else {
                  setCnpj(cnpjMask(e.target.value))
                }
              }}
              value={cnpj}
              controlled
              maxLength={18}
            />
          </div>
          <div className="col-md-4">
            <Input
              label="Razão social/Nome fantasia"
              className="form-control"
              name="person__name"
            />
          </div>
          <div className="col-md-3 mt-0">
            <label className="control-label" style={{ fontWeight: 'normal' }}>
              Segmento
            </label>
            <DropdownList
              className="container-multi-select max-height-34px"
              messages={{
                emptyList: 'Nenhum resultado encontrado',
                emptyFilter: 'Nenhum resultado encontrado'
              }}
              data={(segmentsOptions || []).filter(
                (segment: any) => !segment.parent_id
              )}
              dataKey="value"
              textField="name"
              onChange={() => {
                history.replace('/commercial/clients')
                setSubSegmentsSelected('')
                setDefaultValues((oldDefaultValues: any) => ({
                  ...oldDefaultValues,
                  sub_segment_id: ''
                }))
              }}
              onSelect={data => {
                setSegmentSelected(data.value)
                const filterSubSegments = subSegmentsData.filter(
                  (segment: any) => segment.parent_id === Number(data.value)
                )
                setSubSegmentsOptions(filterSubSegments)
              }}
              value={segmentSelected}
            />
            <Input
              name={'segment_id'}
              style={{ display: 'none', marginTop: '0' }}
              value={segmentSelected}
              controlled
            />
          </div>
          <div className="col-md-3 mt-0">
            <label className="control-label" style={{ fontWeight: 'normal' }}>
              Subsegmento
            </label>
            <DropdownList
              className="container-multi-select max-height-34px"
              messages={{
                emptyList: 'Nenhum resultado encontrado',
                emptyFilter: 'Nenhum resultado encontrado'
              }}
              data={subSegmentsOptions}
              dataKey="value"
              textField="name"
              onSelect={data => {
                setSubSegmentsSelected(data.value)
              }}
              value={subSegmentsSelected}
              disabled={!subSegmentsOptions?.length}
            />
            <Input
              name={'sub_segment_id'}
              style={{ display: 'none', marginTop: '0' }}
              value={subSegmentsSelected}
              controlled
            />
          </div>
          <div className="col-md-2">
            <Select
              label="Tipo cliente"
              className="form-control"
              name="client_type_id"
              options={clientsTypesOptions}
              blank
              defaultValue={''}
            />
          </div>
          <div className="col-md-2">
            <Select
              label="Tipo captação"
              className="form-control"
              name="capture_type"
              options={[
                { name: 'ATIVA', value: 'A' },
                { name: 'PASSIVA', value: 'P' }
              ]}
              blank
              defaultValue={''}
            />
          </div>
          <div className="col-md-2">
            <Select
              label="Origem"
              className="form-control"
              name="client_origin_id"
              options={originsOptions}
              blank
              defaultValue={''}
            />
          </div>
          <div className="col-md-3">
            <Select
              label="Consumidor final"
              className="form-control"
              name="final_consumer"
              options={[
                { name: 'SIM', value: 1 },
                { name: 'NAO', value: 0 }
              ]}
              blank
              defaultValue={''}
            />
          </div>
          <div className="col-md-3">
            <Input
              label="Responsável Venda/Locação"
              className="form-control"
              name="saleSeller__saleSellerName"
            />
          </div>
          <footer className="col-md-12">
            <button
              type="reset"
              onClick={() => {
                history.replace('/commercial/clients')
                setSubSegmentsOptions([])
                setCnpj('')
                setSegmentSelected('')
                setSubSegmentsSelected('')
                setDefaultValues({
                  segment_id: '',
                  sub_segment_id: '',
                  person__cnpj_cpf: '',
                  person__name: '',
                  client_economic_group_id: '',
                  client_type_id: '',
                  capture_type: '',
                  client_origin_id: '',
                  saleSeller__saleSellerName: '',
                  final_consumer: ''
                })
                setParameters([{ segments }])
              }}
            >
              LIMPAR
            </button>
            <button type="submit">BUSCAR</button>
          </footer>
        </Form>
        <hr />
      </Filter>
      <ListTitle>
        <p>Listagem</p>
        <hr style={{ paddingBottom: 10 }} />
      </ListTitle>
      {parameters && (
        <DataTable
          source={nameSource}
          entity={nameEntity}
          format={{ orderBy: 'person.name' }}
          notHasChildren
          headers={headers}
          searchParameters={parameters}
          selectFields={fields.current}
          customHeaders={[
            {
              name: 'Cidade/UF',
              field: 'personAddress.uf',
              sortable: true,
              element: item => {
                const personAddresses = item.person.addresses
                const address = personAddresses[personAddresses.length - 1]
                if (!address?.city && !address?.state) {
                  return <p></p>
                }
                return (
                  <p>
                    {address?.city}/{address?.state}
                  </p>
                )
              }
            }
          ]}
          actions={[
            {
              name: 'Novo orçamento',
              title: 'Novo orçamento',
              spanIcon: 'fa fa-plus',
              onClick: () => {
                window.open(
                  'https://multfluxosistema.com.br/orcamentos/add',
                  '_blank'
                )
              }
            },
            {
              name: 'visualizar',
              title: 'Visualizar',
              spanIcon: 'fa fa-search',
              onClick: item => {
                history.push(`/commercial/clients/view/${item.id}`)
              }
            },
            {
              name: 'editar',
              title: 'Editar',
              spanIcon: 'fa fa-edit',
              onClick: item => {
                history.push(`/commercial/clients/update/${item.id}`)
              }
            }
          ]}
        />
      )}
    </Container>
  )
}

export default ClientsList
