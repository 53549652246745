import React, { useCallback, useEffect, useState } from 'react'
import { useTabs } from '../../../../../../../../../hooks/tabs'
import { useProduct } from '../../../provider/productProvider'
import { Container, Panel, StockItem } from './styles'
import {
  Atributes,
  PriceCompositionView,
  ProductResponse
} from '../../../domain/response/productResponse'
import {
  formatProductTypeToLowerCase,
  RE_SALE,
  SALE
} from '../../../../../domain/data/products'
import { Details, DetailsType } from '../Details'
import { genericMaskWithTwoZero } from '../../../../../../../../../utlis/mask'
import { StockResponseType } from '../../../../../domain/models/productResponse'
import { Alert } from '../../../../../../../../../components/Alert'
import api from '../../../../../../../../../services/api'
import { useToast } from '../../../../../../../../../hooks/toast'
import {
  typeUnitMensuredDetails,
  typeUnitMensuredWeight
} from '../../../../../domain/data/products/unitMensured'

import { HasComposition } from '../HasComposition'
import currency from 'currency.js'
import Modal from '../../../../../../../../../components/Modal'
import { FormStockMoviments } from '../../../../../../../StockMoviments/components/Form'
import { apiList } from '../../../../../domain/data/api'
import { useParams } from 'react-router-dom'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'
import { ListStockMoviments } from './components/ListStockMoviments'

export const labelStock = 'Estoque'
export const nameStock = '@@tabs-view-stock'

const BRL = (value: any) =>
  currency(value, {
    symbol: '',
    decimal: ',',
    separator: '.'
  })
type StockListType = {
  id: number
  name: string
  product?: {
    id: number
    name: string
  }
}
export const Stock = (): JSX.Element => {
  const { id } = useParams<{ id: string }>()
  const { changeTabLabel } = useTabs()
  const { getProduct } = useProduct()
  const { stocks, price_composition, type, product_category } = getProduct()
  const [stock, setStock] = useState<StockResponseType[]>()
  const [currentStock, setCurrentStock] = useState<StockResponseType>()
  let priceCompositionList: PriceCompositionView = {} as PriceCompositionView
  const [stocksList, setStocksList] = useState<StockListType[]>([])
  const [modalStockMoviments, setModalStockMoviments] = useState(false)
  const [historyStockMoviments, setHistoryStockMoviments] = useState<{
    stock_id: string
  } | null>(null)
  const [stockc, setStockc] = useState<any>()

  useEffect(() => {
    ;(async () => {
      const { data } = await api.get<StockListType[]>('warehouse/stocks')
      setStocksList(data)
    })()
  }, [])

  if (price_composition) {
    priceCompositionList = JSON.parse(price_composition.toLowerCase())
  }

  const { addToast } = useToast()

  useEffect(() => {
    if (stocks) {
      const stock = stocks.map(s => ({
        ...s,
        open: false
      }))
      setStock(stock)
      if (stocks.length > 1) {
        changeTabLabel(nameStock, 'Estoque/Variação')
      }
    }
  }, [stocks])

  const isSaleOrResaleType = (): boolean => {
    return (
      getProduct().type?.replace(' ', '-') ===
        formatProductTypeToLowerCase(RE_SALE) ||
      getProduct().type?.replace(' ', '-') ===
        formatProductTypeToLowerCase(SALE)
    )
  }
  const loadStock = async () => {
    const { data } = await api.get<ProductResponse>(apiList(id))

    setStock(data.stocks)
  }

  const gettypeUnitMensuredDetails = (isEqual: string): string => {
    const match = typeUnitMensuredDetails.filter(
      ({ value }) => value === isEqual?.toLowerCase()
    )
    if (match && match[0]) return match[0].label
    return isEqual
  }

  const getTypeUnitMensuredWeight = (isEqual: string): string => {
    const match = typeUnitMensuredWeight.filter(
      ({ value }) => value === isEqual?.toLowerCase()
    )
    if (match && match[0]) return match[0].label
    return isEqual
  }

  const [alertRemoveStock, setAlertRemoveStock] = useState(false)
  const handlerOnClickButtonConfirmRemoveStock = useCallback(
    async (id: number) => {
      try {
        await api.delete(`warehouse/stocks/delete/${String(id)}`)
        setStock(stock => {
          const copy = [...stock]
          const stockIndex = copy.findIndex(s => s.id === currentStock.id)
          if (stockIndex !== -1) {
            copy.splice(stockIndex, 1)
          }
          return copy
        })
        setAlertRemoveStock(false)
        addToast({
          type: 'success',
          title: 'Produto removido com sucesso.'
        })
      } catch (err: any) {
        setAlertRemoveStock(false)
        if (err.response.status === 403) {
          addToast({
            type: 'error',
            title: err.response.data.message
          })
          return
        }
        addToast({
          type: 'error',
          title: 'Produto não pode ser removido, pois ainda está em uso.'
        })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addToast, currentStock]
  )

  const handlerOnClickButtonCancelRemoveStock = useCallback(() => {
    setAlertRemoveStock(false)
  }, [])

  if (stock) {
    return (
      <Container>
        {/*         <TabsContainer>
          {tabs?.map(({ id, name }) => (
            <TabItem
              key={name}
              active={tabs.find(t => t.id === id).active}
              onClick={() => activeStockTab(id)}
            >
              {name}
            </TabItem>
          ))}
        </TabsContainer>
 */}
        {stock.map(
          (
            {
              id,
              name,
              open,
              current_stock,
              replacement_point,
              details,
              atributes,
              price_cost,
              location_value,
              price_sale,
              margin_profit,
              product_units_measured,
              composition,
              product_code,
              needs_personalization,
              accept_art,
              production_deadline,
              built_in_product
            },
            index
          ) => {
            let unitmensured = {
              unit_mensured: { name: '' }
            }

            let atributesList: Atributes[] = []
            let stockDetails: DetailsType = {
              name: '',
              width: '',
              height: '',
              length: '',
              weight: '',
              thickness: '',
              measure: '',
              way_use: '',
              old_product: '',
              old_product_name: '',
              measure_weight: '',
              description_details: '',
              technical_specification: '',
              dimensions: '',
              dimensions_open: '',
              dimensions_closed: '',
              dimensions_calota: '',
              dimensions_tape: '',
              dimensions_rope: ''
            }

            if (details) {
              stockDetails = JSON.parse(details)
            }

            if (product_units_measured) {
              unitmensured = { unit_mensured: product_units_measured }
            }

            if (atributes) {
              atributesList = JSON.parse(atributes)
            }

            const priceCost = genericMaskWithTwoZero(String(price_cost))
            let priceDif = '0'
            let priceIpi = '0'
            let priceFixedCost = '0'
            let priceMarginProfit = '0'
            let priceSimpleNacional = '0'
            if (priceCompositionList.dif) {
              priceDif = BRL(priceCost)
                .multiply(
                  BRL(genericMaskWithTwoZero(String(priceCompositionList.dif)))
                )
                .divide(100)
                .format()
            }
            if (priceCompositionList.ipi) {
              priceIpi = BRL(priceCost)
                .multiply(
                  BRL(genericMaskWithTwoZero(String(priceCompositionList.ipi)))
                )
                .divide(100)
                .format()
            }
            if (priceCompositionList.fixed_cost) {
              priceFixedCost = BRL(priceCost)
                .multiply(
                  BRL(
                    genericMaskWithTwoZero(
                      String(priceCompositionList.fixed_cost)
                    )
                  )
                )
                .divide(100)
                .format()
            }
            if (priceCompositionList.margin_profit) {
              priceMarginProfit = BRL(priceCost)
                .multiply(
                  BRL(
                    genericMaskWithTwoZero(
                      String(priceCompositionList.margin_profit)
                    )
                  )
                )
                .divide(100)
                .format()
            }
            if (priceCompositionList.simple_national) {
              priceSimpleNacional = BRL(priceCost)
                .multiply(
                  BRL(
                    genericMaskWithTwoZero(
                      String(priceCompositionList.simple_national)
                    )
                  )
                )
                .divide(100)
                .format()
            }
            const finalCost = BRL(priceCost)
              .add(priceDif)
              .add(priceIpi)
              .add(priceFixedCost)
              .add(priceMarginProfit)
              .add(priceSimpleNacional)
              .format()

            return (
              <StockItem key={id} className="panel-group" id="accordion">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <h4 className="panel-title">
                      <div
                        onClick={() =>
                          setStock((prev: any) => {
                            const copy = [...prev]
                            copy[index].open = !open
                            return copy
                          })
                        }
                      >
                        {name}
                        {!open ? (
                          <FaChevronDown size={13} />
                        ) : (
                          <FaChevronUp size={13} />
                        )}
                      </div>
                    </h4>
                  </div>
                  <Panel id={`${id}`} className="panel-collapse" open={open}>
                    <div className="panel-body">
                      <div
                        className="row"
                        style={{ display: 'flex', flexWrap: 'wrap' }}
                      >
                        {product_code && (
                          <div className="form-content col-md-3">
                            <label>Código do produto</label>
                            <p>{product_code}</p>
                          </div>
                        )}
                        <div className="form-content col-md-3">
                          <label>Unidade de medidas</label>
                          <p>{unitmensured.unit_mensured.name}</p>
                        </div>
                        {atributesList.map(({ key, value }) => (
                          <div key={value} className="form-content col-md-3">
                            <label>
                              {`${String(key)[0]}${String(key)
                                .toLowerCase()
                                .substring(1)}`}
                            </label>
                            <p>{value}</p>
                          </div>
                        ))}
                        <div className="form-content col-md-3">
                          <label>Estoque atual</label>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '1rem'
                            }}
                          >
                            <p>{current_stock}</p>
                            <span
                              className="fa fa-file-text"
                              title="Histórico de Movimentações"
                              onClick={() => {
                                setHistoryStockMoviments({
                                  stock_id: id.toString()
                                })
                              }}
                            ></span>
                          </div>
                          <div></div>
                        </div>
                        <div className="form-content col-md-3">
                          <label>Reposição de estoque</label>
                          <p>{replacement_point}</p>
                        </div>
                        {stockDetails?.measure_weight && (
                          <div className="form-content col-md-3">
                            <label htmlFor="Peso_medida">Peso medida</label>
                            <p>
                              {getTypeUnitMensuredWeight(
                                stockDetails?.measure_weight
                              )}
                            </p>
                          </div>
                        )}
                        {stockDetails?.length && (
                          <div className="form-content col-md-3">
                            <label htmlFor="Peso">Peso</label>
                            <p>
                              {genericMaskWithTwoZero(stockDetails?.weight)}
                            </p>
                          </div>
                        )}
                        {stockDetails?.measure && stockDetails?.measure !== '' && (
                          <div className="form-content col-md-3">
                            <label htmlFor="tipo do produto">
                              Dimensão medida
                            </label>
                            <p>
                              {gettypeUnitMensuredDetails(
                                stockDetails?.measure
                              )}
                            </p>
                          </div>
                        )}
                        {stockDetails?.width && (
                          <div className="form-content col-md-3">
                            <label htmlFor="tipo do produto">Largura</label>
                            <p>{genericMaskWithTwoZero(stockDetails?.width)}</p>
                          </div>
                        )}
                        {stockDetails?.height && (
                          <div className="form-content col-md-3">
                            <label htmlFor="tipo do produto">Altura</label>
                            <p>
                              {genericMaskWithTwoZero(stockDetails?.height)}
                            </p>
                          </div>
                        )}
                        {stockDetails?.length && (
                          <div className="form-content col-md-3">
                            <label htmlFor="tipo do produto">Comprimento</label>
                            <p>
                              {genericMaskWithTwoZero(stockDetails?.length)}
                            </p>
                          </div>
                        )}
                        {stockDetails?.thickness && (
                          <div className="form-content col-md-3">
                            <label htmlFor="tipo do produto">Espessura</label>
                            <p>
                              {genericMaskWithTwoZero(stockDetails?.thickness)}
                            </p>
                          </div>
                        )}
                        {stockDetails?.old_product_name && (
                          <div className="form-content col-md-3">
                            <label htmlFor="tipo do produto">
                              Produto antigo
                            </label>
                            <p>{stockDetails?.old_product_name}</p>
                          </div>
                        )}
                        {typeof needs_personalization === 'number' && (
                          <div className="form-content col-md-3">
                            <label htmlFor="tipo do produto">
                              Necessita personalização
                            </label>
                            <p>{needs_personalization ? 'SIM' : 'NÃO'}</p>
                          </div>
                        )}
                        <div className="form-content col-md-3">
                          <label htmlFor="tipo do produto">
                            Aceita anexo de arte
                          </label>
                          <p>{accept_art ? 'SIM' : 'NÃO'}</p>
                        </div>
                        {typeof production_deadline === 'number' && (
                          <div className="form-content col-md-3">
                            <label htmlFor="tipo do produto">
                              Prazo de produção
                            </label>
                            <p>{production_deadline}</p>
                          </div>
                        )}
                        {typeof built_in_product === 'number' && (
                          <div className="form-content col-md-3">
                            <label htmlFor="tipo do produto">
                              Produto embutido
                            </label>
                            <p>{built_in_product ? 'SIM' : 'NÃO'}</p>
                          </div>
                        )}
                        {getProduct().type === 'locacao' && (
                          <div className="form-content col-md-3">
                            <label>Preço locação</label>
                            <p>
                              {genericMaskWithTwoZero(String(location_value))}
                            </p>
                          </div>
                        )}
                        {isSaleOrResaleType() && (
                          <>
                            <div className="form-content col-md-3">
                              <label>Custo</label>
                              <p>
                                {genericMaskWithTwoZero(String(price_cost))}
                              </p>
                            </div>
                            <div className="form-content col-md-3">
                              <label>Margem de lucro</label>
                              <p>{genericMaskWithTwoZero(margin_profit)}</p>
                            </div>
                            <div className="form-content col-md-3">
                              <label>Venda</label>
                              <p>
                                {genericMaskWithTwoZero(String(price_sale))}
                              </p>
                            </div>
                            <div className="form-content col-md-3">
                              <label>Preço final</label>
                              <p>{finalCost}</p>
                            </div>
                          </>
                        )}
                      </div>
                      <Details detail={stockDetails} />
                      <HasComposition
                        composition={composition}
                        stocksList={stocksList}
                      />
                      <button
                        className="btn dark btn-sm sbold uppercase"
                        style={{ marginRight: '10px' }}
                        onClick={() => {
                          setModalStockMoviments(true)
                          setStockc({
                            ...stock[index],
                            product: {
                              type,
                              product_category,
                              name: getProduct().name
                            },
                            product_units_measured: {
                              name: unitmensured.unit_mensured.name
                            }
                          })
                        }}
                      >
                        ADICIONAR MOVIMENTAÇÃO
                      </button>
                      {stock.length > 1 && (
                        <button
                          className="btn dark btn-sm sbold uppercase"
                          onClick={() => {
                            if (stock.length > 1) {
                              setCurrentStock(stock[index])
                              setAlertRemoveStock(true)
                            }
                          }}
                        >
                          REMOVER VARIAÇÃO
                        </button>
                      )}
                    </div>
                  </Panel>
                </div>
              </StockItem>
            )
          }
        )}
        <Alert
          message={`Tem certeza que deseja excluir o registro ${currentStock?.name} ?`}
          onClickCancellButton={handlerOnClickButtonCancelRemoveStock}
          onClickConfirmButton={() =>
            handlerOnClickButtonConfirmRemoveStock(Number(currentStock?.id))
          }
          isActive={alertRemoveStock}
        />
        {modalStockMoviments && (
          <Modal
            onClickButtonCancel={() => setModalStockMoviments(false)}
            isOpenModal={modalStockMoviments}
            pageTitle={'Adicionar Movimentação'}
            styles={{
              padding: 0
            }}
            Children={
              <FormStockMoviments
                stock={stockc}
                setModalStockMoviments={setModalStockMoviments}
                loadStock={loadStock}
              />
            }
          />
        )}
        {historyStockMoviments && (
          <Modal
            onClickButtonCancel={() => {
              setHistoryStockMoviments(null)
            }}
            isOpenModal={!!historyStockMoviments}
            pageTitle="Histórico de movimentações"
            styles={{
              padding: 0
            }}
            sectionStyle={{ maxHeight: '700px', overflow: 'auto' }}
            Children={
              <ListStockMoviments stock_id={historyStockMoviments?.stock_id} />
            }
          />
        )}
      </Container>
    )
  }
  return <div></div>
}
