import React, {
  InputHTMLAttributes,
  SelectHTMLAttributes,
  TextareaHTMLAttributes,
  useEffect,
  useState
} from 'react'
import {
  Controller,
  FormProvider,
  RegisterOptions,
  useForm,
  useFormContext
} from 'react-hook-form'
import ReactWidgetDropdownList from 'react-widgets/esm/DropdownList'
import { IconBaseProps } from 'react-icons'
import { FiAlertCircle } from 'react-icons/fi'
import { Contanier, Error, SelectContanier, TextAreaContanier } from './styles'

export default function Form({
  defaultValues,
  children,
  onSubmit,
  hasErrors
}: any) {
  const methods = useForm({ defaultValues, shouldUnregister: true })

  const {
    handleSubmit,
    reset,
    formState: { errors }
  } = methods

  useEffect(() => {
    reset(defaultValues)
  }, [defaultValues, reset])

  if (hasErrors) hasErrors(errors)

  return (
    <FormProvider {...methods}>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        {children}
      </form>
    </FormProvider>
  )
}

type DropdownListProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  rules?: RegisterOptions
  hasError?: any
  label?: string
  labelError?: string
  options: { name: string; value: number | string }[]
  icon?: React.ComponentType<IconBaseProps>
  currentValue: string | number
  setCurrentValue: (data: string | number) => void
}

export function DropdownList({
  name,
  label,
  rules,
  disabled,
  className,
  options,
  currentValue,
  setCurrentValue
}: DropdownListProps) {
  const {
    register,
    setValue,
    formState: { errors }
  } = useFormContext()

  const keys = name.split('.')
  let error = keys.length === 2 ? errors?.[keys[0]]?.[keys[1]] : errors?.[name]
  error = keys.length === 3 ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]] : error
  error =
    keys.length === 4
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]
      : error
  error =
    keys.length === 5
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]?.[keys[4]]
      : error

  return (
    <Contanier erro={error}>
      {label && (
        <label className="control-label" style={{ fontWeight: 'normal' }}>
          {label}
        </label>
      )}
      <ReactWidgetDropdownList
        className={className + ' container-dropdownlist'}
        messages={{
          emptyList: 'Nenhum resultado encontrado',
          emptyFilter: 'Nenhum resultado encontrado'
        }}
        data={options}
        dataKey="value"
        textField="name"
        onSelect={data => {
          setCurrentValue(data.value)
          setValue(name, data.value)
        }}
        disabled={disabled}
        value={currentValue}
        inputProps={{
          ...(register && register(name, rules))
        }}
      />
    </Contanier>
  )
}

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  rules?: RegisterOptions
  hasError?: any
  label?: string
  labelError?: string
  icon?: React.ComponentType<IconBaseProps>
}

export function Input({
  name,
  label,
  labelError,
  icon: Icon,
  rules,
  hasError,
  ...rest
}: InputProps) {
  const {
    register,
    formState: { errors },
    setValue
  } = useFormContext()

  if (rest.value && rest.type !== 'radio') {
    setValue(name, rest.value)
  }
  const keys = name.split('.')
  let error = keys.length === 2 ? errors?.[keys[0]]?.[keys[1]] : errors?.[name]
  error = keys.length === 3 ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]] : error
  error =
    keys.length === 4
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]
      : error
  error =
    keys.length === 5
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]?.[keys[4]]
      : error

  return (
    <Contanier erro={error}>
      {Icon && <Icon size={20} />}
      {label && (
        <label htmlFor={name} className="control-label">
          {label}
        </label>
      )}
      <div>
        <input {...(register && register(name, rules))} {...rest} />
        {error?.message && (
          <Error title={error.message}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
        {hasError?.error && (
          <Error title={hasError?.message}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
        {error?.type === 'required' && (
          <Error title={`O campo ${labelError || label} é obrigatório`}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </div>
    </Contanier>
  )
}

type InputMaskProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string
  rules?: RegisterOptions
  hasError?: any
  label?: string
  labelError?: string
  icon?: React.ComponentType<IconBaseProps>
  mask?: (value: string) => string
}

export function InputMask({
  name,
  label,
  labelError,
  icon: Icon,
  rules,
  hasError,
  mask,
  ...rest
}: InputMaskProps) {
  const {
    formState: { errors },
    control
  } = useFormContext()

  const keys = name.split('.')
  let error = keys.length === 2 ? errors?.[keys[0]]?.[keys[1]] : errors?.[name]
  error = keys.length === 3 ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]] : error
  error =
    keys.length === 4
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]
      : error
  error =
    keys.length === 5
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]?.[keys[4]]
      : error

  return (
    <Contanier erro={error}>
      {Icon && <Icon size={20} />}
      {label && (
        <label htmlFor={name} className="control-label">
          {label}
        </label>
      )}
      <div>
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field }) => (
            <input {...field} value={mask(field.value)} {...rest} />
          )}
        />

        {error?.message && (
          <Error title={error.message}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
        {hasError?.error && (
          <Error title={hasError?.message}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
        {error?.type === 'required' && (
          <Error title={`O campo ${labelError || label} é obrigatório`}>
            <FiAlertCircle color="#c53030" size={20} />
          </Error>
        )}
      </div>
    </Contanier>
  )
}

type SelectProps = SelectHTMLAttributes<HTMLSelectElement> & {
  options: Array<{
    value: string | number
    name: string | number
  }>
  name: string
  label?: string
  rules?: RegisterOptions
  blank?: boolean
}

export function Select({
  options,
  name,
  label,
  rules,
  blank,
  ...rest
}: SelectProps) {
  const {
    register,
    formState: { errors }
  } = useFormContext()

  const keys = name.split('.')
  let error = keys.length === 2 ? errors?.[keys[0]]?.[keys[1]] : errors?.[name]
  error = keys.length === 3 ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]] : error
  error =
    keys.length === 4
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]
      : error
  error =
    keys.length === 5
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]?.[keys[4]]
      : error

  return (
    <SelectContanier erro={error}>
      {label && (
        <label htmlFor={name} className="control-label">
          {label}
        </label>
      )}
      <div>
        <select {...(register && register(name, rules))} {...rest}>
          {blank && (
            <option key={0} value="" disabled selected>
              selecione
            </option>
          )}
          {options.map(option => (
            <option key={option.value} value={option.value}>
              {option.name}
            </option>
          ))}
        </select>
      </div>
    </SelectContanier>
  )
}

type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  name: string
  rules?: RegisterOptions
  label?: string
}

export function Textarea({ name, label, rules, ...rest }: TextareaProps) {
  const {
    register,
    formState: { errors }
  } = useFormContext()

  const keys = name.split('.')
  let error = keys.length === 2 ? errors?.[keys[0]]?.[keys[1]] : errors?.[name]
  error = keys.length === 3 ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]] : error
  error =
    keys.length === 4
      ? errors?.[keys[0]]?.[keys[1]]?.[keys[2]]?.[keys[3]]
      : error

  return (
    <TextAreaContanier erro={error}>
      {label && (
        <label htmlFor={name} className="control-label">
          {label}
        </label>
      )}
      <div>
        <textarea {...(register && register(name, rules))} {...rest} />
      </div>
    </TextAreaContanier>
  )
}
