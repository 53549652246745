export const headers = [
  { name: 'Empresa', field: 'company.name', sortable: true },
  { name: 'Conta', field: 'bank.nome', sortable: true },
  { name: 'Nome', field: 'name', sortable: true },
  { name: 'Valor Aplicado', field: 'value', sortable: true },
  { name: 'Saldo Atualizado', field: 'balance', sortable: true, custom: true },
  {
    name: 'Data de Atualização',
    field: 'last_updated_at',
    sortable: true,
    custom: true
  },
  { name: 'Ações', field: 'actions', sortable: false }
]
export const headersTransaction = [
  { name: 'Operação', field: 'operation', sortable: true, custom: true },
  { name: 'Valor', field: 'value', sortable: true },
  { name: 'IOF', field: 'iof', sortable: false, custom: true },
  {
    name: 'Imp. de renda',
    field: 'income_tax',
    sortable: false,
    custom: true,
    tdStyle: { width: '180px' }
  },
  {
    name: 'Saldo Atualizado',
    field: 'balance',
    sortable: true
  },
  { name: 'Usuário', field: 'username', sortable: true }
]
